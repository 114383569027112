import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // action timeline
    fetcActionList(ctx,user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getActionList`,user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    ///////////// Admin Panel Teams Section
    fetchTeams(ctx,user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTeamList`,user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

   
  
    fetchTeamAndPermissions(ctx, team) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTeamAndPermissions`, team)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateGroupPermissions(ctx, perm) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateGroupPermissions`, perm)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkExistingTeam(ctx, team) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkExistingTeam`, team)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewTeam(ctx, team) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewTeam`, team)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteTeamName(ctx, team) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteTeamName`, team)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // end teams section

    ////////// admin panel currency section
    fetchCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkExistingCurrency(ctx, currency) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkExistingCurrency`, currency)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewCurrency(ctx, currency) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewCurrency`, currency)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteCurrency(ctx, currency) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteCurrency`, currency)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //end currency section


       ////////// admin panel cost_center section
       fetchCostCenters(ctx, user) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getCostCenters`, user)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      checkExistingCostCenter(ctx, cost_center) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`checkExistingCostCenter`, cost_center)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      createNewCostCenter(ctx, cost_center) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`createNewCostCenter`, cost_center)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      deleteCostCenter(ctx, cost_center) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`deleteCostCenter`, cost_center)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },

      updateDefaultCostCenter(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`updateDefaultCostCenter`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },

      getDefaultCostCenters(ctx) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getDefaultCostCenters`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
  
      //end cost_center section



    ////////// admin panel intermediary section
    fetchIntermediaries(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getIntermediaries`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkExistingIntermediary(ctx, intermediary) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkExistingIntermediary`, intermediary)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewIntermediary(ctx, intermediary) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewIntermediary`, intermediary)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteIntermediary(ctx, intermediary) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteIntermediary`, intermediary)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //end currency section

    ////////// admin panel email section

    fetchEmails(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getEmails`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEmails(ctx, emails) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateEmails`, emails)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //end email section

     ////////// admin panel holiday section

     fetchHolidays(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchHolidayCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidayCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewHoliday(ctx, holiday) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewHoliday`, holiday)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteHoliday(ctx, holiday) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteHoliday`, holiday)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //end holiday section

     ////////// admin panel users section

     fetchUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUserTeams(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getUserTeams`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewUser(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewUser`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateUserStatus(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateUserStatus`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchSelectedUserDetails(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fetchSelectedUserDetails`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    fetchRoles(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fetchRoles`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    addRole(ctx, role) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('roles/store', role)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateRole(payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`roles/${payload.role}`, payload.permissions)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


    updateAssignedTeams(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateAssignedTeams`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getuserDetail(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getuserDetail`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateUserData(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateUserData`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    personalSettings(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`personalSettings`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updatePersonalSettings(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updatePersonalSettings`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    
    getDefaultTicketVisibility(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getDefaultTicketVisibility`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateDefaultTicketVisibility(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateDefaultTicketVisibility`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



        
    getTaxRates(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTaxRates`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaxRates(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateTaxRates`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
            
    getCommissions(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCommissions`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createCommission(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createCommission`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteCommission(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteCommission`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    
  }
};
